import { max } from "lodash";

var echarts = require("echarts");

// 饼图 环图
export function loopgraph(data) {
  let option = {
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    legend: {
      type: "scroll",
      icon: "circle",
      orient: "vertical", //设置显示顺序，默认水平（水平，竖直）
      itemWidth: 8, // 设置icon宽度
      itemHeight: 8, // 设置icon高度
      itemGap: 30, // 设置间距
      top: "center", //竖直位置
      right: "20px", //水平位置
      formatter: function (name) {
        var tarValue;
        for (var i = 0, l = data.length; i < l; i++) {
          if (data[i].name == name) {
            tarValue = data[i].value;
          }
        }
        return name + "  " + "{b|" + tarValue + "}";
      },
      textStyle: {
        color: "#000",
        fontSize: 16,
        lineHeight: 24,
        // 添加
        rich: {
          b: {
            fontSize: 16,
            padding: [0, 10],
            height: 24,
            backgroundColor: "#F4647C",
            color: "#fff",
            lineHeight: 24,
            borderRadius: [0, 12, 12, 12],
          },
        },
      },
    },
    //颜色与series data里的按顺序的一一对应
    color: [
      "#5470C6",
      "#91CC75",
      "#FAC858",
      "#EE6666",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
    ],
    series: [
      {
        type: "pie",
        center: ["45%", "50%"],
        radius: ["40%", "60%"],
        clockwise: true,
        avoidLabelOverlap: true,
        hoverOffset: 15,
        //数据
        data: data,
        //折线样式
        label: {
          formatter: "{a|{d}%}\n{hr|}",
          color: "#333333",
          rich: {
            //圆点位置大小配置
            hr: {
              backgroundColor: "auto",
              borderRadius: 3,
              width: 3,
              height: 3,
              padding: [3, 3, 0, -12],
            },
            a: {
              padding: [-12, 10, -20, 15],
            },
          },
        },
        //折线长度
        labelLine: {
          //第一段
          length: 15,
          //第二段
          length2: 25,
        },
      },
    ],
  };
  return option;
}

// 饼图  丁格尔玫瑰图
export function rosegraph(data) {
  let option = {
    //鼠标移动到饼图某一块时弹出
    tooltip: {
      trigger: "item",
      formatter: "{b} : {c}",
    },
    legend: {
      icon: "circle",
      orient: "vertical", //设置显示顺序，默认水平（水平，竖直）
      itemWidth: 8, // 设置icon宽度
      itemHeight: 8, // 设置icon高度
      itemGap: 40, // 设置间距
      top: "center", //竖直位置
      right: "2%", //水平位置
      textStyle: {
        color: "#000",
      }, //文本样式
      // 使用回调函数（设置文本显示内容，例如 加百分号）

      formatter: function (name) {
        var tarValue;
        for (var i = 0, l = data.length; i < l; i++) {
          if (data[i].name == name) {
            tarValue = data[i].value;
          }
        }
        return name + "  " + "{b|" + tarValue + "}";
      },
      textStyle: {
        // 添加
        rich: {
          b: {
            fontSize: 14,
            padding: [3, 10, 0, 10],
            height: 19,
            backgroundColor: "#F4647C",
            color: "#ffffff",
            borderRadius: [0, 11, 11, 11],
          },
        },
      },
    },
    //颜色与series data里的按顺序的一一对应
    color: [
      "#5470C6",
      "#91CC75",
      "#FAC858",
      "#EE6666",
      "#73C0DE",
      "#3BA272",
      "#FC8452",
      "#9A60B4",
    ],
    series: [
      {
        name: "",
        type: "pie",
        radius: ["15%", "70%"],
        center: ["40%", "50%"],
        roseType: "area",
        label: {
          formatter: "{d}%",
        },
        itemStyle: {
          borderRadius: 8,
        },
        //折现图样式
        label: {
          formatter: "{a|{d}%}\n{hr|}",
          color: "#333333",
          rich: {
            //圆点位置大小配置
            hr: {
              backgroundColor: "auto",
              borderRadius: 3,
              width: 3,
              height: 3,
              padding: [3, 3, 0, -12],
            },
            a: {
              padding: [-12, 10, -20, 15],
            },
          },
        },
        labelLine: {
          length: 1,
          length2: 20,
        },
        data: data,
      },
    ],
  };
  return option;
}

// 柱状图
export function histogram(Xdata, Ydata, bgcolor) {
  let option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
      },
      textStyle: {
        align: "left",
      },
      formatter: "{b} : {c}",
    },

    grid: {
      top: "15%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        // type: "category",s
        axisTick: {
          //x轴刻度线
          show: false,
        },
        axisLine: {
          lineStyle: {
            color: "#F8F9FB", // 颜色
          },
        },
        interval: 0,
        axisLabel: {
          textStyle: {
            color: "#333333",
            fontSize: 12,
          },
        },
        data: Xdata,
      },
    ],
    yAxis: [
      {
        type: "value",
        axisTick: {
          //y轴刻度线
          show: false,
        },
        nameTextStyle: {
          color: "#252525",
          fontSize: 12,
        },

        splitLine: {
          show: true,
          lineStyle: {
            color: "#F8F9FB",
          },
        },

        axisLine: {
          lineStyle: {
            color: "#F8F9FB", // 颜色
          },
        },
        axisLabel: {
          interval: 0,
          textStyle: {
            color: "#333333",
            fontSize: 12,
          },
        },
      },
    ],
    series: [
      {
        type: "bar",
        barWidth: 12,
        itemStyle: {
          label: {
            textStyle: {
              color: "#800080",
            },
          },
          normal: {
            //柱形图圆角，初始化效果
            barBorderRadius: [4, 4, 0, 0],
            color: bgcolor,
          },
        },
        data: Ydata,
      },
    ],
  };
  return option;
}

// 横向柱状图
export function broadwise(data) {
  let colorList = ["#F45B5B", "#FE9A3C", "#FED03C", "#DADCE1"];
  let datas = data;

  let maxArr = new Array(datas.length).fill(0).map((item, index) => {
    return index + 1;
  });

  let option = {
    tooltip: {
      show: true,
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: -50,
      right: 30,
      top: "1%",
      bottom: 6,
      containLabel: true,
    },
    xAxis: {
      type: "value",

      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },

      axisLine: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "category",
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisPointer: {
          label: {
            show: false,
            margin: 30,
          },
        },

        data: datas.map((item) => item.name),
        axisLabel: {
          margin: 90,
          fontSize: 14,
          align: "left",
          color: "#333333",
          rich: {
            a1: {
              color: "#fff",
              backgroundColor: colorList[0],
              width: 20,
              height: 20,
              align: "center",
              borderRadius: 10,
            },
            a2: {
              color: "#fff",
              backgroundColor: colorList[1],
              width: 20,
              height: 20,
              align: "center",
              borderRadius: 10,
            },
            a3: {
              color: "#fff",
              backgroundColor: colorList[2],
              width: 20,
              height: 20,
              align: "center",
              borderRadius: 10,
            },
            b: {
              color: "#fff",
              backgroundColor: colorList[3],
              width: 20,
              height: 20,
              align: "center",
              borderRadius: 10,
            },
          },
          formatter: function (params) {
            var index = datas.map((item) => item.name).indexOf(params);
            index = index + 1;
            // 超过四个字省略号
            let paramsval=params.length>4?(params.slice(0, 3)+'...'):params
            if (index - 1 < 3) {
              return ["{a" + index + "|" + index + "}" + "  " + paramsval].join("\n");
            } else {
              return ["{b|" + index + "}" + "  " + paramsval].join("\n");
            }
          },
        },
      },
    ],
    series: [
      {
        name: "备课",
        type: "bar",
        barWidth: 22,
        zlevel: 1,
        data: datas.map((item, i) => {
          var itemStyle = {};
          itemStyle.color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: "#6340C8",
            },
            {
              offset: 1,
              color: "#9C87DB",
            },
          ]);
          return {
            value: item.value,
            itemStyle: itemStyle,
          };
        }),
        itemStyle: {
          barBorderRadius: [2, 2, 2, 2],
        },
      },
    ],
  };

  return option;
}

// 折线图
export function linechart(Xdata, Ydata, bgcolor) {
  let option = {
    tooltip: {
      trigger: "axis",
    },
    color: ["#fcba62", "#69f0ff"],
    legend: {
      x: "left",
      top: "11%",
      left: "8%",
      textStyle: {
        color: "#68a9ff",
        fontSize: 14,
        padding: [0, 8, 0, 8],
      },
    },
    grid: {
      top: "15%",
      left: "10%",
      right: "5%",
      bottom: "9%",
    },
    xAxis: [
      {
        type: "category",
        axisLine: {
          lineStyle: {
            color: "#ffffff",
          },
        },

        axisTick: {
          //x轴刻度线
          show: false,
        },

        axisLabel: {
          color: "#333",
        },
        splitLine: {
          show: false,
        },
        boundaryGap: false,
        data: Xdata,
      },
    ],

    yAxis: [
      {
        axisLine: {
          lineStyle: {
            color: "#ffffff",
            fontSize: 14,
          },
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#ffffff",
          },
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#333333",
          },
        },
      },
    ],
    series: [
      {
        type: "line",
        smooth: true,
        showSymbol: false,
        data: Ydata,

        itemStyle: {
          normal: {
            width: 2,
            color: "#5434AB",
          },
        },
        lineStyle: {
          normal: {
            color: "#4E2EA8",
            width: 3,
          },
        },
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              0,
              0,
              1,
              [
                {
                  offset: 0,
                  color: "rgba(98, 71, 178,0.3)",
                },
                {
                  offset: 0.5,
                  color: "rgba(98, 71, 178,0.1)",
                },
                {
                  offset: 1,
                  color: "rgba(98, 71, 178,0)",
                },
              ],
              false
            ),
          },
        },
      },
    ],
  };
  return option;
}
