<style scoped lang="scss">
.news {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.resource-card {
  height: 22vw;
  display: flex;

  .resource-card-list {
    flex: 1;
    height: 22vw;
    margin-right: 20px;
    border-radius: 10px;
    background-color: #f1f2f7;
    display: flex;
    flex-direction: column;

    .resource-card-box{
      width: 100%;
    }
    .no-data{
      background: transparent;
    }

    .resource-card--title {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      color: #474747;
      position: relative;
      height: 54px;
      line-height: 54px;
      text-indent: 30px;
    }

    .resource-card--title::after {
      content: "";
      width: 5px;
      height: 18px;
      background-color: #6340c8;
      border-radius: 3px;
      position: absolute;
      top: 18px;
      left: 15px;
    }

    .resource-card-chart {
      width: 100%;
      height: 100%;
      flex: 1;
    }
  }

  .resource-card-list:nth-child(3) {
    margin-right: 0;
  }
}

.resource-chart-card {
  height: 3.53vw;
  width: 100%;
  display: flex;
  padding: 0 1.04vw;
  box-sizing: border-box;

  .resource-chart-card-li {
    flex: 1;
    margin-right: 1.04vw;
    background-color: #feaf2f;
    border-radius: 6px;
    padding: 10px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    p {
      color: #fef1db;
      font-size: 14px;
    }

    span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .resource-chart-card-li:nth-child(1) {
    background-image: url(../../assets/images/wrong-question.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: auto 100%;
  }

  .resource-chart-card-li:nth-child(2) {
    background-color: #fe872f;
    background-image: url(../../assets/images/examination-questions.png);
    background-repeat: no-repeat;
    background-position: 120% 0;
    background-size: auto 100%;

  }

  .resource-chart-card-li:nth-child(3) {
    background-color: #F15F5F;
    background-image: url(../../assets/images/courseware.png);
    background-repeat: no-repeat;
    background-position: 150% 0;
    background-size: auto 100%;
    margin-right: 0;
  }
}

// 错题集
.wrong-question-set {
  width: 100%;
  padding: 22px 20px;
  box-sizing: border-box;
  background-color: #f1f2f7;
  border-radius: 10px;
  margin-top: 22px;

  .wrong-question-set-title {
    font-size: 18px;
    font-weight: 600;
    color: #474747;
    position: relative;
    text-indent: 15px;
  }

  .wrong-question-set-title::after {
    content: "";
    width: 5px;
    height: 18px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .wrong-question-get {
    width: 100%;
    height: 380px;
    display: flex;
    box-sizing: border-box;
    padding-bottom: 40px;
  }

  .wrong-question-left {
    width: 33%;
    height: 100%;

    .wrong-question-left-echarts {
      width: 100%;
      height: calc(100% - 52px);
    }
  }

  .wrong-question-classify {
    width: 100%;
    height: 52px;

    .classification {
      display: flex;
      justify-content: center;

      li {
        width: 120px;
        text-align: center;

        span {
          min-width: 54px;
          height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: #f4647c;
          border-radius: 0px 12px 12px 12px;
          color: #fff;
          font-weight: bold;
          box-sizing: border-box;
          padding: 0 8px;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          color: #333333;
          margin-top: 4px;
        }
      }
    }
  }



  .wrong-question-right {
    flex: 1;
    height: 100%;

    &--echarts {
      width: 100%;
      height: calc(100% - 62px);
      margin-top: -20px;
      margin-bottom: 30px;
    }

    .wrong-question-classify {
      box-sizing: border-box;
      padding-left: 60px;

      li span {
        background: #6340c8;
      }
    }
  }

  .wrong-question-bottom {
    width: 100%;
    padding-left: 112px;
    box-sizing: border-box;

    .wrong-question-bottom-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      text-indent: 20px;
      position: relative;
    }

    .wrong-question-bottom-title::after {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #6340c8;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: 5px;
    }

    .wrong-question-bottom-title::before {
      content: "";
      width: 6px;
      height: 6px;
      background-color: #6340c8;
      opacity: 0.3;
      position: absolute;
      border-radius: 50%;
      top: 7px;
      left: 3px;
    }

    .wrong-question-chart {
      width: 100%;
      height: 22.24vw;
      margin-left: -40px;
    }
  }
}

::v-deep {
  .resource-search .el-input--medium .el-input__inner {
    background: #f1f2f8;
    border: none;
    // height: 50px;
    width: 275px;
    line-height: 50px;
    border-radius: 10px;
  }

  // .resource-search .el-form-item--medium .el-form-item__label {
  //   line-height: 50px;
  // }



  .resource-search .el-form--inline .el-form-item {
    margin-right: 17px;
  }
}

// 试题库
.question-bank {
  margin: 20px 0;
  padding: 26px 20px;
  box-sizing: border-box;
  background: #F1F2F7;
  border-radius: 10px;

  .question-bank-title {
    font-size: 18px;
    font-weight: 600;
    color: #474747;
    text-indent: 15px;
    position: relative;
  }

  .question-bank-title::after {
    content: '';
    width: 5px;
    height: 18px;
    background-color: #6340c8;
    border-radius: 2.5px;
    position: absolute;
    top: 1px;
    left: 0;
  }

  .question-bank-tabs {
    display: flex;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 600;
    color: #353535;
    border-bottom: 1px solid #DFDCEE;
    padding-bottom: 25px;
    padding-left: 15px;

    .question-bank-tabs-li {
      width: 110px;
      position: relative;
      cursor: pointer;
    }

    .question-bank-tabs-li.active::after {
      content: '';
      width: 39px;
      height: 6px;
      background-color: #58419c;
      border-radius: 2.5px;
      position: absolute;
      bottom: -25px;

    }

    .question-bank-tabs-li:nth-child(1).active::after {
      left: 3px;

    }

    .question-bank-tabs-li:nth-child(2).active::after {
      right: 3px;

    }

    .question-bank-tabs-li:nth-child(2) {
      border-left: 3px solid #E4E5EA;
      text-align: end;
    }
  }
}

.question-chart {
  width: 100%;
  height: 22.29vw;
  padding: 0 6%;
  box-sizing: border-box;

  .no-data {
    background: transparent;
    margin-top: 30px;

    img {
      width: 320px;
    }
  }

  .question-chart-title {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    text-indent: 20px;
    color: #333333;
    position: relative;
  }

  .question-chart-title::after {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #6340c8;
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 5px;
  }

  .question-chart-title::before {
    content: "";
    width: 6px;
    height: 6px;
    background-color: #6340c8;
    opacity: 0.3;
    position: absolute;
    border-radius: 50%;
    top: 7px;
    left: 3px;
  }

  .question_echart {
    height: 100%;
    width: 100%;
  }

}

.no-data {
  width: 100%;
  height: calc(100% - 72px);
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: #555;
      margin-top: 10px;
    }
  }
}
</style>
<template>
  <section class="news">
    <div class="resource-search">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="学校">
          <el-select v-model="schoolId" placeholder="选择学校" @change="getStatisticsData">
            <el-option v-for="item in schoolList" :key="item.value" :label="item.key" :value="Number(item.value)" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="getStatisticsData">搜索</el-button>
        </el-form-item> -->
      </el-form>
    </div>
    <div :class="{ 'no-data': $isEmpty(statisticsData) }" v-loading="dataLoad">
      <div v-if="!$isEmpty(statisticsData) && !dataLoad">
        <!-- 数据统计 -->
        <div class="resource-card">
          <div class="resource-card-list">
            <div class="resource-card--title">资源类型</div>
            <div class="resource-card-chart" ref="resource_type" style="width: 100%; height: 100%"></div>
          </div>
          <div class="resource-card-list">
            <div class="resource-card--title">数据概括(校本课件库)</div>
            <div class="resource-chart-card">
              <div class="resource-chart-card-li">
                <p>错题库</p>
                <span>{{ statisticsData.resource.schErrorQue.num || 0 }}</span>
              </div>
              <div class="resource-chart-card-li">
                <p>试题库</p>
                <span>{{ statisticsData.resource.schPaper.num || 0 }}</span>
              </div>
              <div class="resource-chart-card-li">
                <p>课件</p>
                <span>{{ statisticsData.resource.schCourseware.num || 0 }}</span>
              </div>
            </div>
            <div class="resource-card-chart data_summary" ref="data_summary"></div>
          </div>
          <div class="resource-card-list">
            <div class="resource-card--title">备课</div>
            <div class="resource-card-chart" ref="ranking_list" v-show="!$isEmpty(statisticsData.ranking.courseware_ranking)"></div>
            <div class="resource-card-box" v-if="$isEmpty(statisticsData.ranking.courseware_ranking)" :class="{ 'no-data': $isEmpty(statisticsData.ranking.courseware_ranking) }">
              <div class="no-data--empty">
                <img src="@assets/images/no-data.png" width="280" alt="">
                <p>暂无统计数据哦~</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 错题集 -->
        <div class="wrong-question-set">
          <div class="wrong-question-set-title">错题集</div>
          <div class="wrong-question-get">
            <div class="wrong-question-left">
              <!-- 重做进度 - 环形进度条 -->
              <div class="wrong-question-left-echarts" ref="wrongquestion_left"></div>
              <div class="wrong-question-classify">
                <ul class="classification">
                  <li>
                    <span>{{ statisticsData.errorQue.all_count || 0 }}</span>
                    <p>总错题集</p>
                  </li>
                  <li>
                    <span>{{ statisticsData.errorQue.all_master_count || 0 }}</span>
                    <p>已掌握</p>
                  </li>
                  <li>
                    <span>{{ statisticsData.errorQue.all_nomaster_count || 0 }}</span>
                    <p>当前剩余</p>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 今日错题状态 -->
            <div class="wrong-question-right">
              <div class="wrong-question-right--echarts" ref="wrongquestion_right"></div>
              <div class="wrong-question-classify">
                <ul class="classification">
                  <li>
                    <span>{{ statisticsData.errorQue.day_add_count || 0 }}</span>
                    <p>今日已掌握</p>
                  </li>
                  <li>
                    <span>{{ statisticsData.errorQue.day_master_count || 0 }}</span>
                    <p>今日新增</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="wrong-question-bottom">
            <div class="wrong-question-bottom-title">错题集分布情况</div>
            <div class="wrong-question-chart" ref="wrong_question"></div>
          </div>
        </div>
        <!-- 试题库 -->
        <div class="question-bank">
          <div class="question-bank-title">
            试题库
          </div>
          <ul class="question-bank-tabs">
            <li class="question-bank-tabs-li" :class="{ active: questiontabs == 1 }"
              @click="[questiontabs = 1, updatePaperEcharts()]">按科目
            </li>
            <li class="question-bank-tabs-li" :class="{ active: questiontabs == 2 }"
              @click="[questiontabs = 2, updatePaperEcharts()]">按年级
            </li>
          </ul>
          <div class="question-chart">
            <div class="question-chart-title">试题库分布情况</div>
            <div ref="question" class="question_echart" v-show="questiontabs == 1 && !$isEmpty(statisticsData.paperData.subjeuctGroup) || questiontabs == 2 && !$isEmpty(statisticsData.paperData.gradeGroup)">
              </div>
            <div v-if="questiontabs == 1 && $isEmpty(statisticsData.paperData.subjeuctGroup) || questiontabs == 2 && $isEmpty(statisticsData.paperData.gradeGroup)"
              :class="{ 'no-data': questiontabs == 1 && $isEmpty(statisticsData.paperData.subjeuctGroup) || questiontabs == 2 && $isEmpty(statisticsData.paperData.gradeGroup) }">
              <div class="no-data--empty"
                >
                <img src="@assets/images/no-data.png" alt="">
                <p>暂无统计数据哦~</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="no-data--empty" v-if="$isEmpty(statisticsData.paperData) && !dataLoad">
        <img src="@assets/images/no-data.png" alt="">
        <p>暂无统计数据哦~</p>
      </div>
    </div>
  </section>
</template>
<script>
import { $schoolList, $statisticsResource } from "@api/dyf_statistics"
import { histogram, linechart, broadwise } from "@/utils/allecharts"

export default {
  data() {
    return {
      schoolId: '',
      schoolList: [], // 学校列表
      statisticsData: {}, // 统计数据
      dataLoad: false, // 页面数据加载
      questiontabs: 1, // 试题库数据根据 1. 科目  2. 年级
      echartsArr: new Array(7), // 页面echarts图表实例
    };
  },
  created() {
    this.getSchoolList();
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.resizeEcharts();
    });
  },

  destroyed() {
    window.removeEventListener('resize', () => {
      this.resizeEcharts();
    })
  },
  methods: {
    /** 获取可选学校列表 */
    async getSchoolList() {
      this.dataLoad = true;
      let { data } = await $schoolList();
      this.schoolList = [...data] || [];
      if (!this.$isEmpty(data)) {
        this.schoolId = data[0].value;
        this.getStatisticsData();
      } else {
        this.dataLoad = false;
      }
      this.$forceUpdate();
    },
    /** 获取页面统计数据 */
    async getStatisticsData() {
      if (!this.dataLoad) this.dataLoad = true;
      let { data } = await $statisticsResource(this.schoolId);
      this.dataLoad = false;
      this.statisticsData = data;
      this.$nextTick(() => {
        this.initEcharts();
        this.$forceUpdate();
      })
    },
    /** 更新页面图表 */
    resizeEcharts() {
      const echartsArr = ['resource_type', 'data_summary', 'ranking_list', 'wrongquestion_left', 'wrongquestion_right', 'wrong_question', 'question'];
      echartsArr.map(item => {
        let el = this.$refs[item];
        this.$echarts.init(el).resize();
      });
      this.$forceUpdate();
    },
    /** 实例化页面图表 */
    initEcharts() {
      const echartsArr = ['resource_type', 'data_summary', 'ranking_list', 'wrongquestion_left', 'wrongquestion_right', 'wrong_question', 'question'];
      let { resource, errorQue, paperData, ranking } = this.statisticsData;
      echartsArr.map((item, index) => {
        this.echartsArr[index] = this.$echarts.init(this.$refs[item]);
        let option, Xdata, Ydata, bgcolor;
        switch (item) {
          // 资源类型
          case 'resource_type':
            option = {
              //鼠标移动到饼图某一块时弹出
              tooltip: {
                trigger: "item",
                formatter: "{b} : {c}",
              },
              legend: {
                icon: "circle",
                orient: "horizontal",
                right: 10,
                y: "bottom",
                x: "center",
                padding: 30,
                itemWidth: 8, // 图例标记的图形宽度
                itemHeight: 8,
              },
              //颜色与series data里的按顺序的一一对应
              color: ["#2AC293", "#6C4DCC", "#EC6933"],
              series: [
                {
                  type: "pie",
                  center: ["50%", "50%"],
                  radius: ["20%", "45%"],
                  clockwise: true,
                  avoidLabelOverlap: true,
                  hoverOffset: 15,
                  //数据
                  data: [
                    { value: resource.schErrorQue.num, rate: resource.schErrorQue.rate, name: "错题库" },
                    { value: resource.schPaper.num, rate: resource.schErrorQue.rate, name: "试题库" },
                    { value: resource.schCourseware.num, rate: resource.schErrorQue.rate, name: "课件库" },
                  ],
                  //折现图样式
                  label: {
                    formatter: "{a|{d}%}\n{hr|}",
                    color: "#333333",
                    rich: {
                      //圆点位置大小配置
                      hr: {
                        backgroundColor: "auto",
                        borderRadius: 3,
                        width: 3,
                        height: 3,
                        padding: [3, 3, 0, -12],
                      },
                      a: {
                        padding: [-12, 10, -20, 15],
                      },
                    },
                  },
                  //折线图长度
                  labelLine: {
                    //第一段
                    length: 15,
                    //第二段
                    length2: 25,
                  },
                },
              ],
            };
            break;
          // 数据概括
          case 'data_summary':
            Xdata = resource.schCoursewareSubject.map(item => item.subject);
            Ydata = resource.schCoursewareSubject.map(item => item.num);
            bgcolor = "#566afd";
            option = histogram(Xdata, Ydata, bgcolor);
            option.tooltip = {
              ...option.tooltip,
              formatter: function (a) {
                let list = []
                let listItem = ''
                for (var i = 0; i < a.length; i++) {
                  list.push(
                    `<p style="padding: 5px 0;" >
                      <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                      <span style="display: inline-block">${a[i].axisValue}</span>
                    </p>
                    <p style="padding-left: 18px;box-sizing: border-box;">${a[i].data}个</p>`
                  )
                }
                listItem = list.join('')
                return `<div style="padding:6px;">${listItem}</div>`
              },
            }
            option.yAxis = {
              ...option.yAxis,
              minInterval: 1,
            }
            break;
          // 备课排行
          case 'ranking_list':
            Xdata = [];
            ranking.courseware_ranking.map(item => {
              let data_item = { value: item.sce_count, name: item.teuse_name };
              Xdata.push(data_item);
            })
            option = broadwise(Xdata);
            break;
          // 错题集 - 重做进度
          case 'wrongquestion_left':
            option = {
              backgroundColor: "#F1F2F7",
              title: [
                {
                  text: "重做进度",
                  x: "center",
                  top: "52%",
                  textStyle: {
                    color: "#6D6D6E",
                    fontSize: 16,
                    fontWeight: "100",
                  },
                },
                {
                  text: `${errorQue.all_schedule}%`,
                  x: "center",
                  top: "42%",
                  textStyle: {
                    fontSize: "24",
                    color: "#EC6D3A",
                    fontFamily: "Lato",
                    foontWeight: "600",
                  },
                },
              ],
              polar: {
                radius: ["40%", "70%"],
                center: ["50%", "50%"],
              },
              angleAxis: {
                max: 100,
                show: false,
              },
              radiusAxis: {
                type: "category",
                show: true,
                axisLabel: {
                  show: false,
                },
                axisLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
              },
              series: [
                {
                  name: "",
                  type: "bar",
                  roundCap: true,
                  barWidth: 80,
                  showBackground: true,
                  backgroundStyle: {
                    color: "#6340C8",
                  },
                  data: [errorQue.all_schedule],
                  coordinateSystem: "polar",

                  itemStyle: {
                    normal: {
                      color: this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                        {
                          offset: 0,
                          color: "#927AD9",
                        },
                      ]),
                    },
                  },
                },
              ],
            };
            break;
          // 错题集 - 近几日错题总数
          case 'wrongquestion_right':
            Xdata = errorQue.chart.map(item => item.time);
            Ydata = errorQue.chart.map(item => item.num);
            bgcolor = "#566afd";
            option = linechart(Xdata, Ydata, bgcolor);
            option.tooltip = {
              ...option.tooltip,
              formatter: '{c}道题',
            }
            break;
          // 错题集 - 分布情况
          case 'wrong_question':
            Xdata = errorQue.sub_data.map(item => item.sysub_name);
            Ydata = errorQue.sub_data.map(item => item.all_sub_count);
            bgcolor = "#2AC293";
            option = histogram(Xdata, Ydata, bgcolor);
            option.tooltip = {
              ...option.tooltip,
              formatter: function (a) {
                let list = []
                let listItem = ''
                for (var i = 0; i < a.length; i++) {
                  list.push(
                    `<p style="padding: 5px 0;" >
                <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                <span style="display: inline-block">${a[i].axisValue}</span>
              </p>
              <p style="padding-left: 18px;box-sizing: border-box;">${a[i].data}道题</p>
              `
                  )
                }
                listItem = list.join('')
                return `<div style="padding:6px;">${listItem}</div>`
              },
            }
            break;
          // 试题库
          case 'question':
            let { gradeGroup, subjeuctGroup } = paperData;
            let data = this.questiontabs == 1 && subjeuctGroup || gradeGroup;
            let x1 = data.map(item => item.sysub_name), x2 = data.map(item => item.grade_name);
            Xdata = this.questiontabs == 1 && x1 || x2;
            Ydata = data.map(item => item.num);
            bgcolor = "#F66477";
            option = histogram(Xdata, Ydata, bgcolor);
            option.tooltip = {
              ...option.tooltip,
              formatter: function (a) {
                let list = []
                let listItem = ''
                for (var i = 0; i < a.length; i++) {
                  list.push(
                    `<p style="padding: 5px 0;" >
                <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                <span style="display: inline-block">${a[i].axisValue}</span>
              </p>
              <p style="padding-left: 18px;box-sizing: border-box;">${a[i].data}道题</p>
              `
                  )
                }
                listItem = list.join('')
                return `<div style="padding:6px;">${listItem}</div>`
              },
            }
            break;
          default:
            break;
        }
        this.echartsArr[index].setOption(option, true);
      });
    },
    /** 更新试卷库图表 */
    updatePaperEcharts() {
      this.echartsArr[6].resize();
      this.echartsArr[6].clear();
      let { paperData } = this.statisticsData;
      let { gradeGroup, subjeuctGroup } = paperData;
      let data = this.questiontabs == 1 && subjeuctGroup || gradeGroup;
      let x1 = data.map(item => item.sysub_name), x2 = data.map(item => item.grade_name);
      let Xdata = this.questiontabs == 1 && x1 || x2;
      let Ydata = data.map(item => item.num);
      let bgcolor = "#F66477";
      let option = histogram(Xdata, Ydata, bgcolor);
      option.tooltip = {
        ...option.tooltip,
        formatter: function (a) {
          let list = []
          let listItem = ''
          for (var i = 0; i < a.length; i++) {
            list.push(
              `<p style="padding: 5px 0;" >
                <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                <span style="display: inline-block">${a[i].axisValue}</span>
              </p>
              <p style="padding-left: 18px;box-sizing: border-box;">${a[i].data}道题</p>
              `
            )
          }
          listItem = list.join('')
          return `<div style="padding:6px;">${listItem}</div>`
        },
      };
      this.echartsArr[6].setOption(option, true);
      this.$forceUpdate();
    },
  },
};
</script>
